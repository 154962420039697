import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {PluginsService} from '../../sofbox/plugins.service';

import { subHeader } from '../../../constants/menu';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class IndexComponent implements OnInit {

  constructor(private plugins: PluginsService) { }

  public navItems: any = [
    { href: '#iq-home', title: 'Home'},
    { href: '#about-us', title: 'About Ridemate' },
    { href: '#feature', title: 'Features' },
    // { href: '#how-it-works', title: 'Service' },
    // { href: '#our-pricing', title: 'Pricing' },
    // { href: '#testimonial', title: 'Testimonial' },
    { href: '#get-in-touch', title: 'Contact' },
    // { href: '#subscribe', title: 'Subscribe' },
    { href: 'blog', title: 'Blog' },
  ];

  public footerText = '© 2018 Sofbox Developed by <b>iqonicthemes</b>.';

  public socialNavItems: any = [
    { href: 'https://twitter.com/MyRideMate', icon: '<i class="fab fa-twitter"></i>' },
    { href: 'https://www.facebook.com/myridemate/', icon: '<i class="fab fa-facebook-f"></i>' },
    { href: 'https://g.page/r/Cfw9DZr8Jm-TEAE', icon: '<i class="fab fa-google"></i>' },
    { href: 'https://www.linkedin.com/company/myridemate', icon: '<i class="fab fa-linkedin"></i>' },
  ];

  ngOnInit() {
    // Init all plugins...
    const current = this;
    // tslint:disable-next-line:only-arrow-functions
    setTimeout(function() {
      current.plugins.index();
    }, 200);
  }

}
