import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-get-in-touch',
  templateUrl: './get-in-touch.component.html',
  styleUrls: ['./get-in-touch.component.css']
})
export class GetInTouchComponent implements OnInit {

  constructor() { }

  public footerText: any = [
    {
      icon: true,
      iconClass: 'ion-ios-location-outline',
      text: '1234 North Luke Lane, South Bend, IN 360001'
    },
    {
      icon: true,
      iconClass: 'ion-ios-telephone-outline',
      text: '+0123 456 789'
    },
    {
      icon: true,
      iconClass: 'ion-ios-email-outline',
      text: 'mail@sofbox.com'
    }
  ];

  public socialNavItems: any = [
    { href: 'https://twitter.com/MyRideMate', icon: '<i class="fab fa-twitter"></i>' },
    { href: 'https://www.facebook.com/myridemate/', icon: '<i class="fab fa-facebook-f"></i>' },
    { href: 'https://g.page/r/Cfw9DZr8Jm-TEAE', icon: '<i class="fab fa-google"></i>' },
    { href: 'https://www.linkedin.com/company/myridemate', icon: '<i class="fab fa-linkedin"></i>' },
  ];


  ngOnInit() {
  }

}
