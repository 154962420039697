import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.css']
})
export class FeatureComponent implements OnInit {

  constructor() { }

  public cardData = [
    {
      title: 'Single Mobile App',
      icon: 'ion-ios-monitor-outline',
      description: 'for all you RideSharing Needs, for both Intercity as well as Intracity share rides'
    },
    // {
    //   title: 'Includes Bike, Car, Auto, TT',
    //   icon: 'ion-ios-albums-outline',
    //   description: 'Designated, Pickup and Drop points to avoid detours'
    // },
    {
      title: 'Meetup place',
      icon: 'ion-ios-color-wand-outline',
      description: 'Meetup place for your long drives and Road trips'
    },
    {
      title: 'Community',
      icon: 'ion-ios-heart-outline',
      description: 'Riders community to share your en-route experience with fellow riders'
    },
    {
      title: 'Get Rewarded',
      icon: 'ion-ios-albums-outline',
      description: 'Share your Ride, Reduce carbon footprint, Save the Environment and Get Rewarded for it'
    },
  ];

  ngOnInit() {
  }

}
